/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'microsoft-teams': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.186 4.797a2.42 2.42 0 10-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 01.682.716v4.294a4.197 4.197 0 01-4.093 4.293c-1.618-.04-3-.99-3.667-2.35zm10.737-9.372a1.674 1.674 0 11-3.349 0 1.674 1.674 0 013.349 0m-2.238 9.488l-.12-.002a5.2 5.2 0 00.381-2.07V6.306a1.7 1.7 0 00-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.6 2.6 0 01-2.598 2.598z"/><path pid="1" d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.68.68 0 01-.682.682H.682A.68.68 0 010 10.853V4.03c0-.377.305-.682.682-.682zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945z"/>',
    },
});
